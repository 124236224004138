/////////////////////////
//  Import Fonts
/////////////////////////
$font-path: '../fonts/';
$font-display: auto;
$font-families: ( 'grotesk', 'rational' );
$font-variants: ( 'regular': ('weight': 400, 'style': normal, 'font-display': $font-display)  );

@each $family in $font-families {
    @each $name, $attr in $font-variants {
        @include font-face($family, #{$font-path}#{$family}#{$name}/font, map-get($attr, 'weight'), map-get($attr, 'style'), map-get($attr, 'font-display'), woff woff2); } }


$lynstone: 'lynstone';

@include font-face($lynstone, #{$font-path}lynstone-Regular, 400, normal,woff2 woff);
@include font-face($lynstone, #{$font-path}lynstone-SemiBold, 600, normal,woff2 woff);
