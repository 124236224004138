@charset "UTF-8";
body {
  --prim: #EBE1CE;
  --prim-light: white;
  --second: #C99C00;
  --second-light: #ffcb17;
  --error: #dd4040;
  --prim: #66dd9a;
  --light-gray: #F7F7F7;
  --medium-gray: #CFCFCF;
  --dark-gray: #8B8989; }

@font-face {
  font-family: "grotesk";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("../fonts/groteskregular/font.woff") format("woff"), url("../fonts/groteskregular/font.woff2") format("woff2"); }

@font-face {
  font-family: "rational";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("../fonts/rationalregular/font.woff") format("woff"), url("../fonts/rationalregular/font.woff2") format("woff2"); }

@font-face {
  font-family: "lynstone";
  font-style: normal;
  font-weight: 400;
  font-display: woff2 woff;
  src: url("../fonts/lynstone-Regular.eot?") format("eot"), url("../fonts/lynstone-Regular.woff2") format("woff2"), url("../fonts/lynstone-Regular.woff") format("woff"), url("../fonts/lynstone-Regular.ttf") format("truetype"), url("../fonts/lynstone-Regular.svg#lynstone") format("svg"); }

@font-face {
  font-family: "lynstone";
  font-style: normal;
  font-weight: 600;
  font-display: woff2 woff;
  src: url("../fonts/lynstone-SemiBold.eot?") format("eot"), url("../fonts/lynstone-SemiBold.woff2") format("woff2"), url("../fonts/lynstone-SemiBold.woff") format("woff"), url("../fonts/lynstone-SemiBold.ttf") format("truetype"), url("../fonts/lynstone-SemiBold.svg#lynstone") format("svg"); }

* {
  letter-spacing: 0.02em; }

p, h1, h2, h3, h4, h5, h6, ul, ol, hr, blockquote {
  margin: 0 0 .5em 0;
  padding: 0;
  font-weight: 400; }

a {
  text-decoration: none;
  transition: opacity .2s ease;
  opacity: 1;
  color: inherit; }
  a:hover {
    opacity: 0.9; }

p {
  margin-bottom: 1em;
  hyphens: none; }

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none; }

cite, figcaption {
  display: inline;
  font-style: normal; }

.image-number {
  color: #8B8989;
  display: inline-block;
  margin-right: 1rem;
  font-family: 'rational'; }

.lead {
  font-size: 1.8rem;
  line-height: 1.3;
  hyphens: none; }
  @media only screen and (min-width: 1024px) {
    .lead {
      font-size: 2rem; } }

h1 {
  font-size: 2.4rem;
  line-height: 1.28;
  margin-bottom: .8rem; }
  @media only screen and (min-width: 1024px) {
    h1 {
      font-size: 2.8rem; } }

h2 {
  font-size: 2.6rem;
  line-height: 1.2;
  margin-bottom: 0.5em; }

h3 {
  font-size: 2.8rem;
  line-height: 1.285;
  margin-bottom: 0.57em; }

h4 {
  font-size: 3.2rem;
  margin-bottom: 0.5em; }

h5 {
  font-size: 1.8rem;
  line-height: 1.2;
  margin-bottom: 0.3em; }
  @media only screen and (min-width: 1024px) {
    h5 {
      font-size: 2.4rem; } }

h6 {
  font-size: 1.2rem;
  line-height: 1.428;
  font-family: 'rational'; }
  @media only screen and (min-width: 1024px) {
    h6 {
      font-size: 1.4rem; } }

blockquote {
  font-size: 3.2rem;
  line-height: 1.25; }
  blockquote:before {
    content: "«"; }
  blockquote:after {
    content: "»"; }

table {
  text-align: left;
  width: 100%;
  line-height: 2rem; }
  table .journal-ajax-button {
    cursor: pointer; }
  table time {
    font-size: 1.4rem; }

thead {
  font-size: 1.4rem;
  font-family: "rational"; }

tr {
  vertical-align: top; }

th, td {
  padding: 1.6rem 2em 1.6rem 0; }

th {
  padding-top: 0; }

tbody tr {
  border-top: 1px solid #CFCFCF; }
  tbody tr:last-child {
    border-bottom: 1px solid #CFCFCF; }

label {
  display: block;
  margin-bottom: .5em; }
  label input[type="text"], label textarea[type="text"],
  label input[type="number"],
  label textarea[type="number"],
  label input[type="email"],
  label textarea[type="email"],
  label input[type="password"],
  label textarea[type="password"],
  label select,
  label textarea {
    display: block;
    width: 100%;
    margin-top: .5em;
    margin-bottom: 1em; }

input, textarea, select {
  display: block;
  border: 1px solid var(--medium-gray);
  padding: 0.3em 0.7em;
  border-radius: 3px;
  background-color: #ffffff;
  outline: 0;
  margin-bottom: 1em; }
  input:focus, textarea:focus, input:active, textarea:active, select:focus, select:active {
    box-shadow: 0 0 5px #CFCFCF; }
    input:focus::placeholder, textarea:focus::placeholder, input:active::placeholder, textarea:active::placeholder, select:focus::placeholder, select:active::placeholder {
      opacity: .6; }
  input::placeholder, textarea::placeholder, select::placeholder {
    opacity: 1; }
  input:valid, textarea:valid, select:valid {
    border-color: #66dd9a; }
  input:disabled, textarea:disabled, select:disabled {
    opacity: 0.5;
    pointer-events: none; }
  input::-webkit-outer-spin-button, textarea::-webkit-outer-spin-button, input::-webkit-inner-spin-button, textarea::-webkit-inner-spin-button, select::-webkit-outer-spin-button, select::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  input[type=number], textarea[type="number"], select[type=number] {
    -moz-appearance: textfield; }
  input[type="checkbox"], textarea[type="checkbox"], select[type="checkbox"] {
    display: inline;
    margin-right: .5em; }
  input[type="radio"], textarea[type="radio"], select[type="radio"] {
    display: inline;
    margin-right: .3em; }

[disabled="disabled"] {
  opacity: .7;
  pointer-events: none; }

select {
  padding: 0.3em 0.4em; }

button,
.button,
input[type="submit"],
textarea[type="submit"],
input[type="button"],
textarea[type="button"] {
  border: none;
  background-color: #F7F7F7;
  padding: 0.19em 1.8rem;
  font-size: initial;
  line-height: 2.8rem;
  border-radius: 2rem;
  margin-bottom: .5em;
  transition: background-color 0.15s cubic-bezier(0.59, 0.35, 0.22, 1);
  cursor: pointer;
  letter-spacing: 0.03em;
  user-select: none;
  display: inline-block; }
  button:hover,
  .button:hover,
  input[type="submit"]:hover,
  textarea[type="submit"]:hover,
  input[type="button"]:hover,
  textarea[type="button"]:hover {
    background-color: #CFCFCF; }
  button.hollow,
  .button.hollow,
  input[type="submit"].hollow,
  textarea[type="submit"].hollow,
  input[type="button"].hollow,
  textarea[type="button"].hollow {
    background: transparent;
    border: 1px solid #CFCFCF;
    transition: border-color .3s ease;
    padding: 1.4rem 2.4rem 1.2rem 2.4rem;
    border-radius: 2.8rem;
    font-size: 2rem; }
    button.hollow:hover,
    .button.hollow:hover,
    input[type="submit"].hollow:hover,
    textarea[type="submit"].hollow:hover,
    input[type="button"].hollow:hover,
    textarea[type="button"].hollow:hover {
      background-color: transparent;
      border-color: #000000; }
  button.download,
  .button.download,
  input[type="submit"].download,
  textarea[type="submit"].download,
  input[type="button"].download,
  textarea[type="button"].download {
    font-size: 2rem;
    line-height: 1.3;
    display: inline-block; }
    button.download:before,
    .button.download:before,
    input[type="submit"].download:before,
    textarea[type="submit"].download:before,
    input[type="button"].download:before,
    textarea[type="button"].download:before {
      content: "↓";
      margin-right: .5em; }

.arrow-button {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 1px solid #CFCFCF;
  border-radius: 50%;
  width: 3.6rem;
  height: 3.6rem;
  font-size: 2rem;
  margin-bottom: 1.6rem;
  transition: border-color .3s ease; }

a:hover .arrow-button,
.arrow-button:hover {
  border-color: #000000; }

.button-group .button,
.filter-cache .button {
  margin-right: .3em;
  display: inline-block; }

.filter-cache .journal-filter {
  background-color: #CFCFCF;
  border-color: #CFCFCF; }

hr {
  margin-bottom: 1em;
  border-bottom: 0;
  border-color: #F7F7F7; }

time {
  font-family: "rational"; }

.content-style ul {
  margin: 0 0 1em 1em;
  list-style: disc; }

.content-style ol {
  margin: 0 0 1em 2em;
  list-style: number; }

.content-style ul a:not(.button), .content-style ol a:not(.button), .content-style h3 a:not(.button), .content-style h4 a:not(.button), .content-style h5 a:not(.button), .content-style h6 a:not(.button), .content-style p a:not(.button), .content-style blockquote a:not(.button) {
  text-decoration-line: underline;
  text-decoration-color: #EBE1CE;
  text-underline-offset: 4px;
  text-decoration-thickness: 2px;
  transition: text-decoration-color .2s ease; }
  .content-style ul a:not(.button):hover, .content-style ol a:not(.button):hover, .content-style h3 a:not(.button):hover, .content-style h4 a:not(.button):hover, .content-style h5 a:not(.button):hover, .content-style h6 a:not(.button):hover, .content-style p a:not(.button):hover, .content-style blockquote a:not(.button):hover {
    text-decoration-color: #000000; }

.content-style ul a.button[target="_blank"]:before,
.content-style ul a.button[href*="mailto:"]:before, .content-style ol a.button[target="_blank"]:before,
.content-style ol a.button[href*="mailto:"]:before, .content-style h3 a.button[target="_blank"]:before,
.content-style h3 a.button[href*="mailto:"]:before, .content-style h4 a.button[target="_blank"]:before,
.content-style h4 a.button[href*="mailto:"]:before, .content-style h5 a.button[target="_blank"]:before,
.content-style h5 a.button[href*="mailto:"]:before, .content-style h6 a.button[target="_blank"]:before,
.content-style h6 a.button[href*="mailto:"]:before, .content-style p a.button[target="_blank"]:before,
.content-style p a.button[href*="mailto:"]:before, .content-style blockquote a.button[target="_blank"]:before,
.content-style blockquote a.button[href*="mailto:"]:before {
  content: "↗";
  margin-right: .5em; }

.center {
  text-align: center; }

.mono {
  font-family: 'rational';
  letter-spacing: 0.01em; }

.login {
  background-color: #FFFFFF;
  color: #0000FF; }
  .login a {
    color: #0000FF; }
  .login #login_error {
    border: 0px solid transparent;
    border: 3px solid #dc3232;
    color: #dc3232; }
  .login .message {
    border: 0px solid transparent;
    border: 3px solid green;
    color: green; }
  .login #login {
    padding: 0;
    max-width: 90vw; }
    .login #login label {
      font-family: "Grotesk", Arial, Helvetica, sans-serif;
      line-height: 1.5; }
  .login form {
    border: 3px solid #0000FF;
    padding-bottom: 25px; }
    .login form input, .login form textarea {
      border: 3px solid #0000FF !important;
      border-radius: 0 0 0 0;
      outline: none;
      -webkit-appearance: none;
      background-color: #FFFFFF;
      box-shadow: 0 0 0 0 transparent;
      transition: all 0.2s ease-in-out; }
      .login form input:focus, .login form textarea:focus, .login form input:active, .login form textarea:active, .login form input:visited, .login form textarea:visited {
        outline: none !important;
        box-shadow: 0 0 0 2px #0000FF !important; }
    .login form #rememberme {
      width: 22px;
      height: 22px;
      display: inline-block; }
    .login form .dashicons {
      color: #0000FF; }
    .login form .user-pass-wrap {
      margin-bottom: 5px; }
      @media only screen and (min-width: 640px) {
        .login form .user-pass-wrap {
          margin-bottom: 20px; } }
    .login form #wp-submit {
      color: #0000FF;
      background-color: transparent;
      font-size: 14px;
      border-radius: 0 0 0 0;
      width: 100%;
      margin-top: 20px; }
      .login form #wp-submit:focus, .login form #wp-submit:active, .login form #wp-submit:visited, .login form #wp-submit:hover {
        background-color: transparent;
        outline: none !important;
        border: 0px solid transparent;
        box-shadow: 0 0 0 2px #0000FF !important; }
      @media only screen and (min-width: 640px) {
        .login form #wp-submit {
          margin-top: 0px;
          width: auto; } }
    .login form .button-secondary.wp-hide-pw {
      padding: 0px;
      height: 38px;
      width: 38px;
      min-height: 38px;
      min-width: 38px;
      margin: 3px; }
      .login form .button-secondary.wp-hide-pw:focus, .login form .button-secondary.wp-hide-pw:active, .login form .button-secondary.wp-hide-pw:visited {
        border-radius: 0 0 0 0;
        border: 0px solid transparent;
        box-shadow: 0 0 0 1px #0000FF !important; }
      .login form .button-secondary.wp-hide-pw:hover {
        border: 0px solid transparent;
        box-shadow: 0 0 0 2px transparent !important; }
  .login #nav a, .login #backtoblog a {
    color: #0000FF; }
    .login #nav a:focus, .login #nav a:active, .login #nav a:visited, .login #nav a:hover, .login #backtoblog a:focus, .login #backtoblog a:active, .login #backtoblog a:visited, .login #backtoblog a:hover {
      color: #0000FF;
      box-shadow: none;
      font-weight: bold; }
  .login h1 a {
    background: url("../img/glunz/GLUNZ_logo.svg");
    width: 150px;
    height: 67px;
    margin: 0 auto 10vh auto; }
    @media only screen and (min-width: 640px) {
      .login h1 a {
        width: 200px;
        height: 90px;
        margin: 0 auto 20vh auto; } }
  .login #nav, .login #backtoblog {
    text-align: center;
    margin-top: 10px; }
    @media only screen and (min-width: 640px) {
      .login #nav, .login #backtoblog {
        margin-top: 20px; } }
  .login .privacy-policy-page-link {
    margin: auto;
    position: absolute;
    display: block;
    bottom: 5px;
    left: 0px;
    text-align: center; }
    @media only screen and (min-width: 640px) {
      .login .privacy-policy-page-link {
        bottom: 20px; } }
    .login .privacy-policy-page-link .privacy-policy-link {
      display: block;
      position: relative; }
      .login .privacy-policy-page-link .privacy-policy-link:focus, .login .privacy-policy-page-link .privacy-policy-link:active, .login .privacy-policy-page-link .privacy-policy-link:visited, .login .privacy-policy-page-link .privacy-policy-link:hover {
        color: #0000FF;
        box-shadow: none;
        font-weight: bold; }

.interim-login.login h1 a {
  width: 150px;
  margin-bottom: 10vh; }
