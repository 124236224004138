* {
    letter-spacing: 0.02em; }

p, h1, h2, h3, h4, h5, h6, ul, ol, hr, blockquote {
    margin: 0 0 .5em 0;
    padding: 0;
    font-weight: 400; }

a {
    text-decoration: none;
    transition: opacity .2s ease;
    opacity: 1;
    color: inherit;

    &:hover {
        opacity: 0.9; } }

p {
    margin-bottom: 1em;
    hyphens: none; }

ul, ol {
    margin: 0;
    padding: 0;
    list-style: none; }

cite, figcaption {
    display: inline;
    font-style: normal; }

.image-number {
    color: $dark-gray;
    display: inline-block;
    margin-right: 1rem;
    font-family: 'rational'; }

.lead {
   font-size: 1.8rem;
   line-height: 1.3;
   hyphens: none;
   @include breakpoint(large) {
       font-size: 2rem; } }


h1 {
    font-size: 2.4rem;
    line-height: 1.28;
    margin-bottom: .8rem;
    @include breakpoint(large) {
        font-size: 2.8rem; } }
h2 {
    font-size: 2.6rem;
    line-height: 1.2;
    margin-bottom: 0.5em; }

h3 {
    font-size: 2.8rem;
    line-height: 1.285;
    margin-bottom: 0.57em; }
h4 {
    font-size: 3.2rem;
    margin-bottom: 0.5em; }
h5 {
    font-size: 1.8rem;
    line-height: 1.2;
    margin-bottom: 0.3em;
    @include breakpoint(large) {
        font-size: 2.4rem; } }

h6 {
    font-size: 1.2rem;
    line-height: 1.428;
    font-family: 'rational';
    @include breakpoint(large) {
        font-size: 1.4rem; } }


blockquote {
    font-size: 3.2rem;
    line-height: 1.25;

    &:before {
        content: "«"; }
    &:after {
        content: "»"; } }

table {
    text-align: left;
    width: 100%;
    line-height: 2rem;

    .journal-ajax-button {
        cursor: pointer; }

    time {
        font-size: 1.4rem; } }

thead {
    font-size: 1.4rem;
    font-family: "rational"; }

tr {
    vertical-align: top; }

th, td {
    padding: 1.6rem 2em 1.6rem 0; }
th {
    padding-top: 0; }

tbody {

    tr {
        border-top: 1px solid $medium-gray;

        &:last-child {
            border-bottom: 1px solid $medium-gray; } } }

label {
    display: block;
    margin-bottom: .5em;

    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"],
    select,
    textarea {
        display: block;
        width: 100%;
        margin-top: .5em;
        margin-bottom: 1em; } }


input, select {
    display: block;
    border: $input-border;
    padding: $input-padding;
    border-radius: $input-radius;
    background-color: $input-background;
    outline: 0;
    margin-bottom: 1em;

    &:focus, &:active {
        box-shadow: 0 0 5px $medium-gray;

        &::placeholder {
                opacity: .6; } }

    &::placeholder {
        opacity: 1; }

    &:valid {
        border-color: $success; }

    &:disabled {
        opacity: 0.5;
        pointer-events: none; }

    // Remove Spinner on number fields
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; }
    &[type=number] {
        -moz-appearance: textfield; }

    &[type="checkbox"] {
        display: inline;
        margin-right: .5em; }


    &[type="radio"] {
        display: inline;
        margin-right: .3em; } }

[disabled="disabled"] {
    opacity: .7;
    pointer-events: none; }

select {
    padding: $select-padding; }

textarea {
    @extend input; }

button,
.button,
input[type="submit"],
input[type="button"] {
    border: $button-border;
    background-color: $button-background;
    padding: $button-padding;
    font-size: $button-font-size;
    line-height: $button-line-height;
    border-radius: $button-radius;
    margin-bottom: .5em;
    transition: $button-transition;
    cursor: pointer;
    letter-spacing: 0.03em;
    user-select: none;
    display: inline-block;


    &:hover {
        background-color: $button-hover-background; }

    &.hollow {
        background: transparent;
        border: 1px solid $medium-gray;
        transition: border-color .3s ease;
        padding: 1.4rem 2.4rem 1.2rem 2.4rem;
        border-radius: 2.8rem;
        font-size: 2rem;

        &:hover {
            background-color: transparent;
            border-color: $black; } }

    &.download {
        font-size: 2rem;
        line-height: 1.3;
        display: inline-block;

        &:before {
            content: "↓";
            margin-right: .5em; } } }

.arrow-button {
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
    border: 1px solid $medium-gray;
    border-radius: 50%;
    width: 3.6rem;
    height: 3.6rem;
    font-size: 2rem;
    margin-bottom: 1.6rem;
    transition: border-color .3s ease; }

a:hover .arrow-button,
.arrow-button:hover {
    border-color: $black; }

.button-group,
.filter-cache {
    .button {
        margin-right: .3em;
        display: inline-block; } }

.filter-cache {
    .journal-filter {
        background-color: $button-hover-background;
        border-color: $button-hover-background; } }

hr {
    margin-bottom: 1em;
    border-bottom: 0;
    border-color: $light-gray; }

time {
    font-family: "rational"; }


.content-style {
    ul {
        margin: 0 0 1em 1em;
        list-style: disc; }

    ol {
        margin: 0 0 1em 2em;
        list-style: number; }

    ul, ol, h3, h4, h5, h6, p, blockquote {
        a:not(.button) {
            text-decoration-line: underline;
            text-decoration-color: $primary-color;
            text-underline-offset: 4px;
            text-decoration-thickness: 2px;
            transition: text-decoration-color .2s ease;
            &:hover {
                text-decoration-color: $black; } }

        a.button[target="_blank"],
        a.button[href*="mailto:"] {
            &:before {
                content: "↗";
                margin-right: .5em; } } } }


.center {
    text-align: center; }

.mono {
    font-family: 'rational';
    letter-spacing: 0.01em; }

