/////////////////////////
//  Path
/////////////////////////
$img-path               : '../img/';



/////////////////////////
//  Typography
/////////////////////////
$base-font-size         : 62.5%; // Cheat typo — Explenation 05_typography.sass
$main-line-height       : 1.5;
$main-font-family       : 'Grotesk', Arial, Helvetica, sans-serif;
$aab-font               : 'lynstone', Arial, Helvetica, sans-serif;



/////////////////////////
//   Grid sttings & Breakpoints
/////////////////////////
$site-width             : 1980px;
$breakpoints            : ( small: (0px), medium: (640px), large: (1024px), xlarge: (1440px) );

$grid-columns           : 12;
$column-gap             : 3.2rem;
$column-small-gap       : 1.6rem;



/////////////////////////
//   Transiton
/////////////////////////
$easy-In                : cubic-bezier(.59,.35,.22,1);
$easy-Out               : cubic-bezier(.99,.01,.58,1);


/////////////////////////
//   Colors
/////////////////////////
$white                   : #ffffff;
$gray                    : #eee;
$black                   : #000000;

$light-gray: #F7F7F7;
$medium-gray: #CFCFCF;
$dark-gray: #8B8989;

$primary-color           : #EBE1CE;
$primary-light           : lighten($primary-color, 15%);
$secondary-color         : #C99C00;
$secondary-light         : lighten($secondary-color, 15%);

$aab                     : #EBEDE0;
$dark-olive              : #C1C9B3;
$orange                  : #F5CC80;

$error                   : #dd4040;
$success                 : #66dd9a;

/////////////////////////
//   Login Colors
/////////////////////////
$background-color        : #FFFFFF;
$main-color              : #0000FF;
$form-bg                 : #FFFFFF;


/////////////////////////
//  Forms
/////////////////////////
$input-radius           : 3px;
$input-border           : 1px solid var(--medium-gray);
$input-padding          : .3em .7em;
$select-padding         : .3em .4em;
$input-background       : $white;


/////////////////////////
//  Buttons
/////////////////////////
$button-radius           : 2rem;
$button-border           : none;
$button-padding          : .19em 1.8rem;
$button-background       : $light-gray;
$button-hover-background : $medium-gray;
$button-font-size        : initial;
$button-line-height      : 2.8rem;
$button-transition       : background-color .15s $easy-In;


body {
    // Define CSS Variables
    --prim: #{$primary-color};
    --prim-light: #{lighten($primary-color, 15%)};
    --second: #{$secondary-color};
    --second-light: #{lighten($secondary-color, 15%)};

    --error: #{$error};
    --prim: #{$success};

    --light-gray: #{$light-gray};
    --medium-gray: #{$medium-gray};
    --dark-gray: #{$dark-gray}; }
